var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isFetching,
      expression: "isFetching"
    }],
    staticClass: "global-spinner"
  }, [_vm._m(0)]), !_vm.isLoading ? _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('Col', {
    attrs: {
      "lg": 16,
      "md": 16,
      "xs": 24,
      "sm": 24
    }
  }, [_c('Card', [_vm.isFetching ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _vm._e(), _c('template', {
    slot: "title"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.routeQuery && _vm.routeQuery.propertyId ? _vm.$t('global.edit') : _vm.$t('global.edit')) + " " + _vm._s(_vm.$t('global.graph.website')) + " ")])]), _c('Form', {
    ref: "propertiesEditForm",
    attrs: {
      "model": _vm.formValidate,
      "rules": _vm.formRules
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.routeQuery.propertyId ? _vm.handleSubmit('propertiesEditForm') : _vm.create('propertiesEditForm');
      }
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('global.property.enable_ui'),
      "prop": "isEnabled"
    }
  }, [_c('Tooltip', {
    attrs: {
      "max-width": "500",
      "content": _vm.$t('global.property.enable_ui_c'),
      "placement": "top-start"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.formValidate.isEnabled,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "isEnabled", $$v);
      },
      expression: "formValidate.isEnabled"
    }
  }), _vm.formValidate.isEnabled ? _c('span', {
    staticClass: "chips chips--success"
  }, [_vm._v(_vm._s(_vm.$t('global.property.enable')))]) : _c('span', {
    staticClass: "chips chips--red"
  }, [_vm._v(_vm._s(_vm.$t('global.property.disable')))])], 1)], 1), _c('FormItem', {
    attrs: {
      "label": _vm.$t('Import properties by CSV'),
      "prop": "websites"
    }
  }, [_c('i', {
    staticClass: "fa fa-question",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "type": "primary",
      "icon": "ios-help"
    },
    on: {
      "click": function click($event) {
        _vm.helpModal = true;
      }
    }
  }), _c('Modal', {
    attrs: {
      "width": "600px",
      "title": "Csv format to use"
    },
    model: {
      value: _vm.helpModal,
      callback: function callback($$v) {
        _vm.helpModal = $$v;
      },
      expression: "helpModal"
    }
  }, [_c('span', [_c('strong', [_c('a', {
    attrs: {
      "href": "https://docs.google.com/spreadsheets/d/1afhGnNIMvY6qCkcne0eZZ8VK-yIvyUUzVTPQ-4lwmag/edit?usp=sharing",
      "target": "_blank"
    }
  }, [_vm._v("Link")])])]), _c('br'), _c('figure', [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.imgUrl
    }
  })])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.file && !_vm.routeQuery.propertyId ? _c('span', {
    staticStyle: {
      "margin-right": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.instance('');
      }
    }
  }, [_vm._v("website found: " + _vm._s(_vm.parsedFile.length))]) : _vm._e(), !_vm.routeQuery.propertyId ? _c('Upload', {
    attrs: {
      "before-upload": _vm.handleUpload,
      "action": "//jsonplaceholder.typicode.com/posts/",
      "type": "drag"
    }
  }, [!_vm.file && !_vm.routeQuery.propertyId ? _c('div', {
    staticStyle: {
      "padding": "20px 0"
    }
  }, [_c('Icon', {
    staticStyle: {
      "color": "#3399ff"
    },
    attrs: {
      "type": "ios-cloud-upload",
      "size": "52"
    }
  }), _c('p', [_vm._v("Drag or import property csv list")])], 1) : _c('span', [_vm._v(_vm._s(_vm.file.name))])]) : _vm._e()], 1), !_vm.file && !_vm.routeQuery.propertyId ? _c('label', {
    staticStyle: {
      "font-size": "12px",
      "color": "#495060",
      "font-weight": "400"
    }
  }, [_vm._v("Import property by url")]) : _vm._e(), !_vm.file ? _c('Input', {
    attrs: {
      "type": "url",
      "placeholder": "https://www.example.com"
    },
    on: {
      "on-change": _vm.checkDisplayName
    },
    model: {
      value: _vm.formValidate.websites,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "websites", $$v);
      },
      expression: "formValidate.websites"
    }
  }) : _vm._e(), !_vm.file && _vm.formValidate.websites.length ? _c('span', {
    on: {
      "click": function click($event) {
        return _vm.redirectTo(_vm.formValidate.websites);
      }
    }
  }, [_vm._v(" preview in new window ")]) : _vm._e(), _vm.similarUrlProperties.length > 0 ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, _vm._l(_vm.similarUrlProperties, function (similarUrlProperty) {
    return _c('p', {
      key: similarUrlProperty.id
    }, [_vm._v(" \"" + _vm._s(similarUrlProperty.target_url) + "\" already requested in (" + _vm._s(similarUrlProperty.isEnabled == 1 ? 'enabled' : 'disabled') + ") property \"" + _vm._s(similarUrlProperty.displayName) + "\" ")]);
  }), 0) : _vm._e()], 1), !_vm.file ? _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.table.name') + ' - Should have the following format : [collect zone] target url',
      "prop": "displayName"
    }
  }, [_c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-width": "500",
      "content": _vm.$t('global.property.display_name'),
      "placement": "top-start"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "[zone] url"
    },
    model: {
      value: _vm.formValidate.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "displayName", $$v);
      },
      expression: "formValidate.displayName"
    }
  })], 1), _vm.similarProperties.length > 0 ? _c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, _vm._l(_vm.similarProperties, function (similarProperty) {
    return _c('p', {
      key: similarProperty.id
    }, [_vm._v(" \"" + _vm._s(similarProperty.displayName) + "\" already exists and (" + _vm._s(similarProperty.isEnabled == 1 ? 'enabled' : 'disabled') + ") with \"" + _vm._s(similarProperty.collectFrequency) + "\" collect frequency ")]);
  }), 0) : _vm._e()], 1) : _vm._e(), _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.property.industry'),
      "prop": "_sri_seller_category"
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": _vm.$t('global.property.select_industry'),
      "clearable": ""
    },
    model: {
      value: _vm.formValidate._sri_seller_category,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "_sri_seller_category", $$v);
      },
      expression: "formValidate._sri_seller_category"
    }
  }, _vm._l(_vm.options.industries, function (option) {
    return _c('Option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('hr'), _c('br'), _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.property.enable_collect'),
      "prop": "isCollectEnabled"
    }
  }, [_c('Tooltip', {
    attrs: {
      "max-width": "500",
      "content": _vm.$t('global.property.enable_collect_c'),
      "placement": "top-start"
    }
  }, [_c('i-switch', {
    on: {
      "on-change": _vm.handleCollectChange
    },
    model: {
      value: _vm.formValidate.isCollectEnabled,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "isCollectEnabled", $$v);
      },
      expression: "formValidate.isCollectEnabled"
    }
  }), _vm.formValidate.isCollectEnabled ? _c('span', {
    staticClass: "chips chips--success"
  }, [_vm._v(_vm._s(_vm.$t('global.property.enable')))]) : _c('span', {
    staticClass: "chips chips--red"
  }, [_vm._v(_vm._s(_vm.$t('global.property.disable')))])], 1)], 1), _vm.formValidate.isCollectEnabled ? [_c('FormItem', {
    attrs: {
      "label": _vm.$t('Proof of scan')
    }
  }, [_c('Checkbox', {
    model: {
      value: _vm.formValidate.collectOptions.proofOfScan,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "proofOfScan", $$v);
      },
      expression: "formValidate.collectOptions.proofOfScan"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.table.collect_frequency'),
      "prop": "collectFrequency"
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": _vm.$t('global.property.collect_freqency'),
      "clearable": ""
    },
    model: {
      value: _vm.formValidate.collectFrequency,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate, "collectFrequency", $$v);
      },
      expression: "formValidate.collectFrequency"
    }
  }, _vm._l(_vm.options.collectFrequency, function (option) {
    return _c('Option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), !_vm.file ? _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.table.collect_country'),
      "prop": "collectCountry"
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": _vm.$t('global.countries.france')
    },
    on: {
      "on-change": _vm.checkDisplayName
    },
    model: {
      value: _vm.formValidate.collectOptions.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "countryCode", $$v);
      },
      expression: "formValidate.collectOptions.countryCode"
    }
  }, _vm._l(_vm.options.collectCountry, function (option) {
    return _c('Option', {
      key: option.name,
      attrs: {
        "value": option.countryCode
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1) : _vm._e(), _c('FormItem', {
    attrs: {
      "label": _vm.$t('Browser Language'),
      "prop": "collectBrowserLanguages"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": true,
      "placeholder": "fr-FR",
      "clearable": ""
    },
    on: {
      "on-change": _vm.checkDisplayName
    },
    model: {
      value: _vm.formValidate.collectOptions.browserLanguages,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "browserLanguages", $$v);
      },
      expression: "formValidate.collectOptions.browserLanguages"
    }
  }, _vm._l(_vm.options.langCodes, function (value, key) {
    return _c('Option', {
      key: key,
      attrs: {
        "value": key
      }
    }, [_vm._v(" " + _vm._s(key) + " > " + _vm._s(value) + " ")]);
  }), 1)], 1), _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.collects.browser_v')
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": _vm.$t('global.collects.browser_v'),
      "clearable": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.browser,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "browser", $$v);
      },
      expression: "formValidate.collectOptions.browser"
    }
  }, _vm._l(_vm.options.browser, function (option, k) {
    return _c('Option', {
      key: k,
      attrs: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.property.user_a'),
      "prop": "userAgent"
    }
  }, [_c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-width": "500",
      "content": " default value:  ".concat(_vm.baseUserAgent()),
      "placement": "top-start"
    }
  }, [_c('Select', {
    attrs: {
      "placeholder": _vm.$t('User-Agent to present'),
      "clearable": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.userAgent,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "userAgent", $$v);
      },
      expression: "formValidate.collectOptions.userAgent"
    }
  }, _vm._l(_vm.options.userAgent, function (option, k) {
    return _c('Option', {
      key: k,
      attrs: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1), _c('Input', {
    attrs: {
      "type": "text",
      "placeholder": ""
    },
    on: {
      "on-change": _vm.handleCustomUserAgentChange
    },
    model: {
      value: _vm.formValidate.collectOptions.userAgent,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "userAgent", $$v);
      },
      expression: "formValidate.collectOptions.userAgent"
    }
  })], 1)], 1), _c('Tooltip', {
    attrs: {
      "content": _vm.$t('Authentification for logged session'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "label": "Logged session"
    }
  }, [_c('Checkbox', {
    on: {
      "on-change": _vm.resetAuthOptions
    },
    model: {
      value: _vm.formValidate.collectOptions.useAuth,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "useAuth", $$v);
      },
      expression: "formValidate.collectOptions.useAuth"
    }
  })], 1)], 1), _vm.formValidate.collectOptions.useAuth ? _c('Timeline', [_c('TimelineItem', [_vm.formValidate.collectOptions.useAuth ? _c('FormItem', {
    attrs: {
      "label": "Auth start url"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "https://www.exemple.com/auth"
    },
    model: {
      value: _vm.formValidate.collectOptions.authStartUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "authStartUrl", $$v);
      },
      expression: "formValidate.collectOptions.authStartUrl"
    }
  })], 1) : _vm._e()], 1), _c('TimelineItem', [_vm.formValidate.collectOptions.useAuth ? _c('FormItem', {
    attrs: {
      "prop": "authUsername",
      "label": "Username"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "allowed_user_1"
    },
    model: {
      value: _vm.formValidate.collectOptions.authUsername,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "authUsername", $$v);
      },
      expression: "formValidate.collectOptions.authUsername"
    }
  })], 1) : _vm._e()], 1), _c('TimelineItem', [_vm.formValidate.collectOptions.useAuth ? _c('FormItem', {
    attrs: {
      "prop": "authPassword",
      "label": "Password"
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": "adminroot"
    },
    model: {
      value: _vm.formValidate.collectOptions.authPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "authPassword", $$v);
      },
      expression: "formValidate.collectOptions.authPassword"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "content": _vm.$t('Idle duration after page load'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('Page delay before action in seconds 1-30s')
    }
  }, [_c('br'), _c('Slider', {
    attrs: {
      "min": 1,
      "max": 30,
      "show-input": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.pageTimeoutSeconds,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "pageTimeoutSeconds", $$v);
      },
      expression: "formValidate.collectOptions.pageTimeoutSeconds"
    }
  })], 1)], 1), _c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "content": _vm.$t('Scroll duration after page load in seconds'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('Page scroll duration in seconds 1-20s')
    }
  }, [_c('br'), _c('Slider', {
    attrs: {
      "min": 1,
      "max": 20,
      "show-input": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.pageScrollDurationSeconds,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "pageScrollDurationSeconds", $$v);
      },
      expression: "formValidate.collectOptions.pageScrollDurationSeconds"
    }
  })], 1)], 1), _c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "content": _vm.$t('Play all video tags visible in the page'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('Try to autoplay videos on pages')
    }
  }, [_c('Checkbox', {
    model: {
      value: _vm.formValidate.collectOptions.pageVideosAutoplay,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "pageVideosAutoplay", $$v);
      },
      expression: "formValidate.collectOptions.pageVideosAutoplay"
    }
  })], 1)], 1), _vm.formValidate.collectOptions.pageVideosAutoplay ? _c('Timeline', [_c('TimelineItem', [_c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "content": _vm.$t('Time to wait in seconds after triggering the play button of the video players.'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('Time to wait in seconds after triggering the play button of the video players.')
    }
  }, [_c('br'), _c('Slider', {
    attrs: {
      "min": 1,
      "max": 300,
      "show-input": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.videosPlayDurationSeconds,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "videosPlayDurationSeconds", $$v);
      },
      expression: "formValidate.collectOptions.videosPlayDurationSeconds"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('Tooltip', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "content": _vm.$t('Amount of url crawled by the bot'),
      "placement": "top-start"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": _vm.$t('global.property.max_url') + ' 4-500',
      "prop": "maxUrls"
    }
  }, [_c('br'), _c('Slider', {
    attrs: {
      "min": 4,
      "max": 500,
      "show-input": ""
    },
    model: {
      value: _vm.formValidate.collectOptions.maxUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.formValidate.collectOptions, "maxUrls", $$v);
      },
      expression: "formValidate.collectOptions.maxUrls"
    }
  })], 1)], 1), _c('FormItem', {
    attrs: {
      "label": "Monthly cost estimation"
    }
  }, [_c('br'), _c('span', [_vm._v(_vm._s(Number.parseFloat(_vm.estimateCost).toFixed(2)))])])] : _vm._e(), _c('FormItem', {
    attrs: {
      "prop": "origin"
    }
  }, [_c('Input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "value": "Agnostik"
    }
  })], 1), _c('FormItem', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.routeQuery.propertyId ? _vm.handleSubmit('propertiesEditForm') : _vm.create('propertiesEditForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.routeQuery.propertyId ? _vm.$t('global.update') : _vm.$t('global.create')) + " ")]), _c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.handleReset('propertiesEditForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.reset')) + " ")])], 1)], 2)], 2)], 1), _vm.routeQuery.propertyId && _vm.formValidate.validation_status && !_vm.isFetching ? _c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [_c('Card', {
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("LAST SCANS")]), _c('Timeline', _vm._l(_vm.dataResp.data.collects, function (collect) {
    return _c('TimelineItem', {
      key: collect.id
    }, [_c('p', {
      staticClass: "time"
    }, [_vm._v(" " + _vm._s(_vm.moment(collect.modified).format('Y-MM-DD HH:mm')) + " ")]), _c('p', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(collect.option_cmp_scenario_id) + " : " + _vm._s(collect.scenario_status) + " "), collect.scenario_status === 'IDLE' ? _c('i', {
      staticClass: "fas fa-pause"
    }) : _vm._e(), collect.scenario_status === 'RUNNING' ? _c('i', {
      staticClass: "fas fa-sync-alt"
    }) : _vm._e(), collect.scenario_status == 'FAILED' ? _c('i', {
      staticClass: "fas fa-times"
    }) : _vm._e(), collect.scenario_status === 'SUCCEEDED' ? _c('i', {
      staticClass: "fas fa-check-circle"
    }) : _vm._e(), _c('br'), !!collect.proof_of_scan_url ? _c('Button', {
      attrs: {
        "size": "small",
        "type": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.redirectTo(collect.proof_of_scan_url);
        }
      }
    }, [_vm._v(" GIF ")]) : _vm._e(), _vm._v("  "), !!collect.har_tracing_file_url ? _c('Button', {
      attrs: {
        "size": "small",
        "type": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.redirectTo(collect.har_tracing_file_url);
        }
      }
    }, [_vm._v(" HAR ")]) : _vm._e()], 1)]);
  }), 1)], 1), _c('br'), _c('Card', {
    staticStyle: {
      "height": "inherit!important"
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" VALIDATION : "), _c('i', {
    class: _vm.formValidate.validation_status
  }, [_vm._v(_vm._s(_vm.validationStatus) + " ")]), _vm.formValidate.validation_status == 'FAILED' ? _c('i', {
    staticClass: "fas fa-times"
  }) : _vm._e(), _vm.formValidate.validation_status === 'WAITING_FOR_VALIDATION' || _vm.formValidate.validation_status === 'QUEUED' ? _c('i', {
    staticClass: "fas fa-sync-alt"
  }) : _vm._e(), _vm.formValidate.validation_status === 'SUCCEEDED' ? _c('i', {
    staticClass: "fas fa-check-circle"
  }) : _vm._e(), _vm.formValidate.validation_status === 'FAILED' ? _c('Button', {
    staticStyle: {
      "position": "absolute",
      "top": "8px",
      "right": "10px"
    },
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.modalOpen = true;
      }
    }
  }, [_vm._v(" Details ")]) : _vm._e(), _c('Modal', {
    attrs: {
      "title": "Error details",
      "width": "1200"
    },
    model: {
      value: _vm.modalOpen,
      callback: function callback($$v) {
        _vm.modalOpen = $$v;
      },
      expression: "modalOpen"
    }
  }, [_vm.formValidate.validation_last_metadata && _vm.formValidate.validation_last_metadata.screenshots.length ? _c('Card', {
    attrs: {
      "title": "Screenshot"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.formValidate.validation_last_metadata.screenshots[0],
      "alt": _vm.formValidate.validation_last_metadata.screenshots[0],
      "width": "800"
    }
  })]) : _vm._e(), _c('Card', {
    attrs: {
      "title": "JSON"
    }
  }, [_c('VueJsonPretty', {
    attrs: {
      "data": _vm.formValidate.validation_last_metadata,
      "deep": 1
    }
  })], 1)], 1)], 1), [_c('p', [_vm._v("Last validation date : " + _vm._s(_vm.moment(_vm.formValidate.modified).format('Y-MM-DD HH:mm')))]), _c('br'), _c('p', [_vm._v("Antibot : " + _vm._s(_vm.checkAntiBot()))]), _c('br'), _c('div', [_vm.formValidate.validation_status === 'FAILED' ? [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.admin.collect_error_title')))])]), _c('ul', [_c('br'), _c('li', [_vm._v(" - " + _vm._s(_vm.$t('global.admin.collect_error_r1')) + " ")]), _c('li', [_vm._v(" - " + _vm._s(_vm.$t('global.admin.collect_error_r2')) + " ")])]), _c('br'), _c('br')] : _vm._e(), _vm.formValidate.validation_last_metadata && _vm.formValidate.validation_last_metadata.screenshots.length ? _c('figure', {
    staticStyle: {
      "width": "100%",
      "margin": "0 auto"
    }
  }, [_c('img', {
    staticClass: "zoom-hover",
    attrs: {
      "src": _vm.formValidate.validation_last_metadata.screenshots[0],
      "alt": _vm.formValidate.validation_last_metadata.screenshots[0],
      "width": "100%"
    },
    on: {
      "click": function click($event) {
        return _vm.redirectTo(_vm.formValidate.validation_last_metadata.screenshots[0]);
      }
    }
  })]) : _c('span', [_vm._v(" No Screenshot found ")])], 2)]], 2)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner"
  }, [_c('div', {
    staticClass: "half"
  })]);

}]

export { render, staticRenderFns }