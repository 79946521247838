<template>
  <div>
    <div
      v-show="isFetching"
      class="global-spinner"
    >
      <div class="spinner">
        <div class="half" />
      </div>
    </div>
    <transition
      name="slide-fade"
      v-if="!isLoading"
    >
      <Row :gutter="40">
        <Col :lg="16"
             :md="16"
             :xs="24"
             :sm="24"
        >
          <Card>
            <Spin size="large"
                  fix
                  v-if="isFetching"
            />
            <template slot="title">
              <span>
                {{ routeQuery && routeQuery.propertyId ? $t('global.edit') : $t('global.edit') }} {{ $t('global.graph.website') }}
              </span>
              <!-- <span
                v-show="formValidate.validation_status === 'WAITING_FOR_VALIDATION' || formValidate.validation_status === 'QUEUED'"
                id="property-extra-waiting-validation"
              >
                Validation in progress
                <i class="fas fa-sync-alt" />
              </span> -->
            </template>
            <Form
              ref="propertiesEditForm"
              :model="formValidate"
              :rules="formRules"
              @submit.prevent.stop="routeQuery.propertyId ? handleSubmit('propertiesEditForm') : create('propertiesEditForm')"
            >
              <FormItem
                :label="$t('global.property.enable_ui')"
                prop="isEnabled"
              >
                <Tooltip
                  max-width="500"
                  :content="$t('global.property.enable_ui_c')"
                  placement="top-start"
                >
                  <i-switch v-model="formValidate.isEnabled" />
                  <span
                    v-if="formValidate.isEnabled"
                    class="chips chips--success"
                  >{{ $t('global.property.enable') }}</span>
                  <span
                    v-else
                    class="chips chips--red"
                  >{{ $t('global.property.disable') }}</span>
                </Tooltip>
              </FormItem>

              <FormItem :label="$t('Import properties by CSV')"
                        prop="websites"
              >
                <!-- <Checkbox v-model="importList"
                          v-if="!routeQuery.propertyId"
                /> -->
                <i style="cursor:pointer"
                   type="primary"
                   @click="helpModal = true"
                   class="fa fa-question"
                   icon="ios-help"
                />
                <Modal
                  width="600px"
                  v-model="helpModal"
                  title="Csv format to use"
                >
                  <span> <strong><a href="https://docs.google.com/spreadsheets/d/1afhGnNIMvY6qCkcne0eZZ8VK-yIvyUUzVTPQ-4lwmag/edit?usp=sharing"
                                    target="_blank"
                  >Link</a></strong></span>
                  <br>

                  <figure>
                    <img :src="imgUrl"
                         style="width:100%"
                    >
                  </figure>
                </Modal>
                <div style="width:100%">
                  <span style="margin-right:20px; cursor:pointer"
                        v-if="file && !routeQuery.propertyId"
                        @click="instance('')"
                  >website found: {{ parsedFile.length }}</span>
                  <Upload
                    v-if="!routeQuery.propertyId"
                    :before-upload="handleUpload"
                    action="//jsonplaceholder.typicode.com/posts/"
                    type="drag"
                  >
                    <div style="padding: 20px 0"
                         v-if="!file && !routeQuery.propertyId"
                    >
                      <Icon type="ios-cloud-upload"
                            size="52"
                            style="color: #3399ff"
                      />
                      <p>Drag or import property csv list</p>
                    </div>
                    <span v-else>{{ file.name }}</span>
                  </Upload>
                </div>
                <label v-if="!file && !routeQuery.propertyId"
                       style="font-size: 12px;color: #495060;font-weight:400"
                >Import property by url</label>
                <Input
                  v-if="!file"
                  type="url"
                  placeholder="https://www.example.com"
                  v-model="formValidate.websites"
                  @on-change="checkDisplayName"
                />
                <span v-if="!file && formValidate.websites.length"
                      @click="redirectTo(formValidate.websites)"
                >
                  preview in new window
                </span>
                <Alert v-if="similarUrlProperties.length>0"
                       type="warning"
                       show-icon
                >
                  <p v-for="similarUrlProperty of similarUrlProperties"
                     :key="similarUrlProperty.id"
                  >
                    "{{ similarUrlProperty.target_url }}" already requested in ({{ similarUrlProperty.isEnabled == 1?'enabled':'disabled' }}) property "{{ similarUrlProperty.displayName }}"
                  </p>
                </Alert>
              </FormItem>
              <FormItem
                v-if="!file"
                :label="$t('global.table.name') + ' - Should have the following format : [collect zone] target url'"
                prop="displayName"
              >
                <Tooltip
                  max-width="500"
                  style="width:100%"
                  :content="$t('global.property.display_name')"
                  placement="top-start"
                >
                  <Input
                    type="text"
                    v-model="formValidate.displayName"
                    placeholder="[zone] url"
                  />
                </Tooltip>
                <Alert v-if="similarProperties.length>0"
                       type="warning"
                       show-icon
                >
                  <p v-for="similarProperty of similarProperties"
                     :key="similarProperty.id"
                  >
                    "{{ similarProperty.displayName }}" already exists and ({{ similarProperty.isEnabled == 1?'enabled':'disabled' }}) with "{{ similarProperty.collectFrequency }}" collect frequency
                  </p>
                </Alert>
              </FormItem>
              <FormItem
                :label="$t('global.property.industry')"
                prop="_sri_seller_category"
              >
                <Select
                  v-model="formValidate._sri_seller_category"
                  :placeholder="$t('global.property.select_industry')"
                  clearable
                >
                  <Option
                    v-for="option in options.industries"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.name }}
                  </Option>
                </Select>
              </FormItem>
              <hr>
              <br>
              <FormItem
                :label="$t('global.property.enable_collect')"
                prop="isCollectEnabled"
              >
                <Tooltip
                  max-width="500"
                  :content="$t('global.property.enable_collect_c')"
                  placement="top-start"
                >
                  <i-switch
                    v-model="formValidate.isCollectEnabled"
                    @on-change="handleCollectChange"
                  />
                  <span
                    v-if="formValidate.isCollectEnabled"
                    class="chips chips--success"
                  >{{ $t('global.property.enable') }}</span>
                  <span
                    v-else
                    class="chips chips--red"
                  >{{ $t('global.property.disable') }}</span>
                </Tooltip>
              </FormItem>
              <template v-if="formValidate.isCollectEnabled">
                <FormItem
                  :label="$t('Proof of scan')"
                >
                  <Checkbox v-model="formValidate.collectOptions.proofOfScan" />
                </FormItem>
                <FormItem
                  :label="$t('global.table.collect_frequency')"
                  prop="collectFrequency"
                >
                  <Select
                    v-model="formValidate.collectFrequency"
                    :placeholder="$t('global.property.collect_freqency')"
                    clearable
                  >
                    <Option
                      v-for="option in options.collectFrequency"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name }}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem
                  v-if="!file"
                  :label="$t('global.table.collect_country')"
                  prop="collectCountry"
                >
                  <Select
                    v-model="formValidate.collectOptions.countryCode"
                    :placeholder="$t('global.countries.france')"
                    @on-change="checkDisplayName"
                  >
                    <Option
                      v-for="option in options.collectCountry"
                      :value="option.countryCode"
                      :key="option.name"
                    >
                      {{ option.name }}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem
                  :label="$t('Browser Language')"
                  prop="collectBrowserLanguages"
                >
                  <Select
                    v-model="formValidate.collectOptions.browserLanguages"
                    :multiple="true"
                    placeholder="fr-FR"
                    clearable
                    @on-change="checkDisplayName"
                  >
                    <Option
                      v-for="(value, key) in options.langCodes"
                      :value="key"
                      :key="key"
                    >
                      {{ key }} > {{ value }}
                    </Option>
                  </Select>
                </FormItem>

                <!-- <FormItem :label="$t('Collect time slots')">
                  <CheckboxGroup v-model="formValidate.collectOptions.timeSlots">
                    <br>
                    <Checkbox :label="item.value"
                              v-for="(item, key) of options.timeSlots"
                              :key="key"
                    >
                      <span>{{ item.key }}</span>
                    </Checkbox>
                  </CheckboxGroup>
                </FormItem> -->


                <FormItem
                  :label="$t('global.collects.browser_v')"
                >
                  <Select
                    v-model="formValidate.collectOptions.browser"
                    :placeholder="$t('global.collects.browser_v')"
                    clearable
                  >
                    <Option
                      v-for="(option, k) in options.browser"
                      :value="option.value"
                      :key="k"
                    >
                      {{ option.name }}
                    </Option>
                  </Select>
                </FormItem>
                <FormItem
                  :label="$t('global.property.user_a')"
                  prop="userAgent"
                >
                  <Tooltip
                    max-width="500"
                    style="width:100%"
                    :content="` default value:  ${baseUserAgent()}`"
                    placement="top-start"
                  >
                    <Select
                      v-model="formValidate.collectOptions.userAgent"
                      :placeholder="$t('User-Agent to present')"
                      clearable
                    >
                      <Option
                        v-for="(option, k) in options.userAgent"
                        :value="option.value"
                        :key="k"
                      >
                        {{ option.name }}
                      </Option>
                    </Select>

                    <Input
                      @on-change="handleCustomUserAgentChange"
                      type="text"
                      placeholder=""
                      v-model="formValidate.collectOptions.userAgent"
                    />
                  </Tooltip>
                </FormItem>
                <!-- <FormItem
                  :label="$t('Proxy server configuration')"
                  prop="proxyServer"
                >
                  <Input
                    type="text"
                    placeholder="protocol://user:pass@host:port"
                    v-model="formValidate.collectOptions.proxyServer"
                  />
                </FormItem> -->
                <Tooltip
                  :content="$t('Authentification for logged session')"
                  placement="top-start"
                >
                  <FormItem label="Logged session"
                            style="display:flex"
                  >
                    <Checkbox v-model="formValidate.collectOptions.useAuth"
                              @on-change="resetAuthOptions"
                    />
                  </FormItem>
                </Tooltip>
                <Timeline v-if="formValidate.collectOptions.useAuth">
                  <TimelineItem>
                    <FormItem v-if="formValidate.collectOptions.useAuth"
                              label="Auth start url"
                    >
                      <Input type="text"
                             placeholder="https://www.exemple.com/auth"
                             v-model="formValidate.collectOptions.authStartUrl"
                      />
                    </FormItem>
                  </TimelineItem>
                  <TimelineItem>
                    <FormItem v-if="formValidate.collectOptions.useAuth"
                              prop="authUsername"
                              label="Username"
                    >
                      <Input type="text"
                             placeholder="allowed_user_1"
                             v-model="formValidate.collectOptions.authUsername"
                      />
                    </FormItem>
                  </TimelineItem>
                  <TimelineItem>
                    <FormItem v-if="formValidate.collectOptions.useAuth"
                              prop="authPassword"
                              label="Password"
                    >
                      <Input type="text"
                             placeholder="adminroot"
                             v-model="formValidate.collectOptions.authPassword"
                      />
                    </FormItem>
                  </TimelineItem>
                </Timeline>

                <Tooltip
                  :content="$t('Idle duration after page load')"
                  style="width:100%"
                  placement="top-start"
                >
                  <FormItem
                    :label="$t('Page delay before action in seconds 1-30s')"
                  >
                    <br>
                    <Slider v-model="formValidate.collectOptions.pageTimeoutSeconds"
                            :min="1"
                            :max="30"
                            show-input
                    />
                  </FormItem>
                </Tooltip>
                <Tooltip
                  style="width:100%"
                  :content="$t('Scroll duration after page load in seconds')"
                  placement="top-start"
                >
                  <FormItem
                    :label="$t('Page scroll duration in seconds 1-20s')"
                  >
                    <br>
                    <Slider v-model="formValidate.collectOptions.pageScrollDurationSeconds"
                            :min="1"
                            :max="20"
                            show-input
                    />
                  </FormItem>
                </Tooltip>
                <Tooltip
                  :content="$t('Play all video tags visible in the page')"
                  style="width:100%"
                  placement="top-start"
                >
                  <FormItem
                    :label="$t('Try to autoplay videos on pages')"
                  >
                    <Checkbox v-model="formValidate.collectOptions.pageVideosAutoplay" />
                  </FormItem>
                </Tooltip>
                <Timeline v-if="formValidate.collectOptions.pageVideosAutoplay">
                  <TimelineItem>
                    <Tooltip
                      :content="$t('Time to wait in seconds after triggering the play button of the video players.')"
                      style="width:100%"
                      placement="top-start"
                    >
                      <FormItem
                        :label="$t('Time to wait in seconds after triggering the play button of the video players.')"
                      >
                        <br>
                        <Slider v-model="formValidate.collectOptions.videosPlayDurationSeconds"
                                :min="1"
                                :max="300"
                                show-input
                        />
                      </FormItem>
                    </Tooltip>
                  </TimelineItem>
                </Timeline>

                <!-- <FormItem
                  :label="$t('global.collects.capture_time')"
                  prop="collectCountry"
                >
                  <br>
                  <Slider v-model="formValidate.collectOptions.pageTimeoutSeconds"
                          show-input
                  />
                </FormItem> -->
                <Tooltip
                  :content="$t('Amount of url crawled by the bot')"
                  style="width:100%"
                  placement="top-start"
                >
                  <FormItem
                    :label="$t('global.property.max_url')+ ' 4-500'"
                    prop="maxUrls"
                  >
                    <br>
                    <Slider v-model="formValidate.collectOptions.maxUrls"
                            :min="4"
                            :max="500"
                            show-input
                    />
                  </FormItem>
                </Tooltip>
                <FormItem label="Monthly cost estimation">
                  <br>
                  <span>{{ Number.parseFloat(estimateCost).toFixed(2) }}</span>
                </FormItem>
              </template>
              <FormItem prop="origin">
                <Input
                  style="display:none"
                  value="Agnostik"
                />
              </FormItem>

              <FormItem style="text-align:right">
                <Button
                  type="primary"
                  class="btn btn--elevated"
                  @click="routeQuery.propertyId ? handleSubmit('propertiesEditForm') : create('propertiesEditForm')"
                >
                  {{ routeQuery.propertyId ? $t('global.update') : $t('global.create') }}
                </Button>
                <Button
                  @click="handleReset('propertiesEditForm')"
                  type="text"
                >
                  {{ $t('global.reset') }}
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Col>
        <Col :lg="8"
             :md="8"
             :xs="24"
             :sm="24"
             v-if="routeQuery.propertyId && formValidate.validation_status && !isFetching"
        >
          <Card style="height: inherit!important">
            <span slot="title">LAST SCANS</span>
            <Timeline>
              <TimelineItem v-for="collect in dataResp.data.collects"
                            :key="collect.id"
              >
                <p class="time">
                  {{ moment( collect.modified).format('Y-MM-DD HH:mm') }}
                </p>
                <p class="content">
                  {{ collect.option_cmp_scenario_id }} :
                  {{ collect.scenario_status }}
                  <!-- icons -->
                  <i class="fas fa-pause"
                     v-if="collect.scenario_status === 'IDLE'"
                  />
                  <i class="fas fa-sync-alt"
                     v-if="collect.scenario_status === 'RUNNING'"
                  />
                  <i v-if="collect.scenario_status == 'FAILED'"
                     class="fas fa-times"
                  />
                  <i v-if="collect.scenario_status === 'SUCCEEDED'"
                     class="fas fa-check-circle"
                  />
                  <br>
                  <Button v-if="!!collect.proof_of_scan_url"
                          size="small"
                          type="primary"
                          @click="redirectTo(collect.proof_of_scan_url)"
                  >
                    GIF
                  </Button>&nbsp;
                  <Button v-if="!!collect.har_tracing_file_url"
                          size="small"
                          type="primary"
                          @click="redirectTo(collect.har_tracing_file_url)"
                  >
                    HAR
                  </Button>
                </p>
              </TimelineItem>
            </Timeline>
          </Card>
          <br>
          <Card style="height: inherit!important">
            <span
              slot="title"
            >
              VALIDATION :
              <i :class="formValidate.validation_status">{{ validationStatus }} </i>
              <!-- icons -->
              <i v-if="formValidate.validation_status == 'FAILED'"
                 class="fas fa-times"
              />
              <i class="fas fa-sync-alt"
                 v-if="formValidate.validation_status === 'WAITING_FOR_VALIDATION' || formValidate.validation_status === 'QUEUED'"
              />
              <i v-if="formValidate.validation_status === 'SUCCEEDED'"
                 class="fas fa-check-circle"
              />

              <Button
                v-if="formValidate.validation_status === 'FAILED'"
                style="position: absolute; top: 8px; right:10px"
                size="small"
                type="primary"
                @click="modalOpen = true"
              >
                Details
              </Button>
              <Modal
                v-model="modalOpen"
                title="Error details"
                width="1200"
              >
                <Card title="Screenshot"
                      v-if="formValidate.validation_last_metadata && formValidate.validation_last_metadata.screenshots.length"
                >
                  <img
                    :src="formValidate.validation_last_metadata.screenshots[0]"
                    :alt="formValidate.validation_last_metadata.screenshots[0]"
                    width="800"
                  >
                </Card>
                <Card title="JSON">
                  <VueJsonPretty
                    :data="formValidate.validation_last_metadata"
                    :deep="1"
                  />
                </Card>
              </Modal>
            </span>
            <template>
              <p>Last validation date : {{ moment(formValidate.modified).format('Y-MM-DD HH:mm') }}</p>
              <br>
              <p>Antibot : {{ checkAntiBot() }}</p>
              <br>
              <div>
                <template v-if="formValidate.validation_status === 'FAILED'">
                  <p><strong>{{ $t('global.admin.collect_error_title') }}</strong></p>
                  <ul>
                    <br>
                    <li> - {{ $t('global.admin.collect_error_r1') }} </li>
                    <li> - {{ $t('global.admin.collect_error_r2') }} </li>
                  </ul>
                  <br><br>
                </template>
                <figure style="width: 100%; margin: 0 auto;"
                        v-if="formValidate.validation_last_metadata && formValidate.validation_last_metadata.screenshots.length"
                >
                  <img
                    class="zoom-hover"
                    :src="formValidate.validation_last_metadata.screenshots[0]"
                    :alt="formValidate.validation_last_metadata.screenshots[0]"
                    width="100%"
                    @click="redirectTo(formValidate.validation_last_metadata.screenshots[0])"
                  >
                </figure>
                <span v-else> No Screenshot found </span>
              </div>
            </template>
          </Card>
        </col>
      </Row>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import * as Papa from 'papaparse'
import imgUrl from '@/assets/images/csv_example.png'
import psl from 'psl'
export default {
  name: 'AdminPropertiesView',
  components: {
    VueJsonPretty
  },
  data () {
    return {
      similarProperties: [],
      similarUrlProperties: [],
      imgUrl: imgUrl,
      helpModal: false,
      file: false,
      parsedFile: [],
      parsedFileImportStatus: [],
      importList: false,
      // eslint-disable-next-line no-useless-escape
      // urlRegex: new RegExp(/^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/igm),
      priceEstimation: 0,
      dataResp: null,
      moment: moment,
      numberRegex: new RegExp('^\\d+$'),
      routeQuery: false,
      isFetching: false,
      isLoading: false,
      options: {
        collectFrequency: [
          {id: 'daily', name: 'daily'},
          {id: 'monthly', name: 'monthly'},
          {id: 'once', name: 'once'}
        ],
        videosPlayDurationSeconds:5,
        timeSlots: [
          {
            key: '02h00-04h00',
            value: '2-4'
          },
          {
            key: '04h00-06h00',
            value: '4-6'
          },
          {
            key: '06h00-08h00',
            value: '6-8'
          },
          {
            key: '08h00-10h00',
            value: '8-10'
          },
          {
            key: '10h00-12h00',
            value: '10-12'
          },
          {
            key: '12h00-14h00',
            value: '12-14'
          },
          {
            key: '14h00-16h00',
            value: '14-16'
          },
          {
            key: '16h00-18h00',
            value: '16-18'
          },
          {
            key: '18h00-20h00',
            value: '18-20'
          },
          {
            key: '20h00-22h00',
            value: '20-22'
          },
        ],
        browser: [
          {
            name: 'chrome_v93_amd64 (2023 default)',
            value: 'chrome_v93_amd64'
          },
          {
            name: 'chromium_v81_722778 (deprecated in Q3 2023)',
            value: 'chromium_v81_722778'
          },
          // {
          //   name: 'chromium_v74_638880',
          //   value: 'chromium_v74_638880'
          // }
        ],
        userAgent:[
          {
            name: 'Facettes (2023 default)',
            value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Agnostik/1.0',
          },
          {
            name: 'Facettes pre - 2023',
            value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36 Agnostik/1.0',
          },
          {
            name: 'Didomi CR 2023',
            value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36 Didomi/1.0'
          },
          {
            name: 'Didomi legacy CR pre - 2023',
            value: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36 Didomi/1.0',
          },
          {
            name: 'Custom',
            value:''
          },
        ],
        collectCountry: [
          {
            name:  'BEL > ' + this.$t('global.countries.belgium'),
            countryCode: 'BEL'
          },
          {
            name:  'DEU > ' + this.$t('global.countries.germany'),
            countryCode: 'DEU'
          },
          {
            name: 'FRA > ' + this.$t('global.countries.france'),
            countryCode: 'FRA'
          },
          {
            name: 'GBR > ' + this.$t('global.countries.great_britain'),
            countryCode: 'GBR'
          },
          {
            name: 'ESP > ' + this.$t('global.countries.spain'),
            countryCode: 'ESP'
          },
          {
            name: 'USA > ' + this.$t('global.countries.united_states'),
            countryCode: 'USA'
          }
        ],
        industries: [
          {name: 'TV & Radio', id: 'TV & Radio'},
          {name: 'News media', id: 'News media'},
          {name: 'Retail & services', id: 'Retail & services'}
        ],
        langCodes : {
          // "af":    "Afrikaans",
          // "sq":    "Albanian",
          // "an":    "Aragonese",
          // "ar":    "Arabic (Standard)",
          // "ar-dz": "Arabic (Algeria)",
          // "ar-bh": "Arabic (Bahrain)",
          // "ar-eg": "Arabic (Egypt)",
          // "ar-iq": "Arabic (Iraq)",
          // "ar-jo": "Arabic (Jordan)",
          // "ar-kw": "Arabic (Kuwait)",
          // "ar-lb": "Arabic (Lebanon)",
          // "ar-ly": "Arabic (Libya)",
          // "ar-ma": "Arabic (Morocco)",
          // "ar-om": "Arabic (Oman)",
          // "ar-qa": "Arabic (Qatar)",
          // "ar-sa": "Arabic (Saudi Arabia)",
          // "ar-sy": "Arabic (Syria)",
          // "ar-tn": "Arabic (Tunisia)",
          // "ar-ae": "Arabic (U.A.E.)",
          // "ar-ye": "Arabic (Yemen)",
          // "hy":    "Armenian",
          // "as":    "Assamese",
          // "ast":   "Asturian",
          // "az":    "Azerbaijani",
          // "eu":    "Basque",
          // "bg":    "Bulgarian",
          // "be":    "Belarusian",
          // "bn":    "Bengali",
          // "bs":    "Bosnian",
          // "br":    "Breton",
          // "my":    "Burmese",
          // "ca":    "Catalan",
          // "ch":    "Chamorro",
          // "ce":    "Chechen",
          // "zh":    "Chinese",
          // "zh-hk": "Chinese (Hong Kong)",
          // "zh-cn": "Chinese (PRC)",
          // "zh-sg": "Chinese (Singapore)",
          // "zh-tw": "Chinese (Taiwan)",
          // "cv":    "Chuvash",
          // "co":    "Corsican",
          // "cr":    "Cree",
          "hr":    "Croatian",
          "cs":    "Czech",
          "da":    "Danish",
          "nl":    "Dutch (Standard)",
          "nl-BE": "Dutch (Belgian)",
          "en":    "English",
          "en-AU": "English (Australia)",
          "en-BZ": "English (Belize)",
          "en-CA": "English (Canada)",
          "en-IE": "English (Ireland)",
          "en-JM": "English (Jamaica)",
          "en-NZ": "English (New Zealand)",
          "en-PH": "English (Philippines)",
          "en-ZA": "English (South Africa)",
          "en-TT": "English (Trinidad & Tobago)",
          "en-GB": "English (United Kingdom)",
          "en-US": "English (United States)",
          "en-ZW": "English (Zimbabwe)",
          // "eo":    "Esperanto",
          // "et":    "Estonian",
          // "fo":    "Faeroese",
          // "fj":    "Fijian",
          "fi":    "Finnish",
          "fr":    "French (Standard)",
          "fr-BE": "French (Belgium)",
          "fr-CA": "French (Canada)",
          "fr-FR": "French (France)",
          "fr-LU": "French (Luxembourg)",
          "fr-MC": "French (Monaco)",
          "fr-CH": "French (Switzerland)",
          // "fy":    "Frisian",
          // "fur":   "Friulian",
          // "gd":    "Gaelic (Scots)",
          // "gd-ie": "Gaelic (Irish)",
          // "gl":    "Galacian",
          // "ka":    "Georgian",
          "de":    "German (Standard)",
          "de-AT": "German (Austria)",
          "de-DE": "German (Germany)",
          "de-LI": "German (Liechtenstein)",
          "de-LU": "German (Luxembourg)",
          "de-CH": "German (Switzerland)",
          "el":    "Greek",
          // "gu":    "Gujurati",
          // "ht":    "Haitian",
          // "he":    "Hebrew",
          // "hi":    "Hindi",
          "hu":    "Hungarian",
          "is":    "Icelandic",
          // "id":    "Indonesian",
          // "iu":    "Inuktitut",
          // "ga":    "Irish",
          "it":    "Italian (Standard)",
          "it-CH": "Italian (Switzerland)",
          // "ja":    "Japanese",
          // "kn":    "Kannada",
          // "ks":    "Kashmiri",
          // "kk":    "Kazakh",
          // "km":    "Khmer",
          // "ky":    "Kirghiz",
          // "tlh":   "Klingon",
          // "ko":    "Korean",
          // "ko-kp": "Korean (North Korea)",
          // "ko-kr": "Korean (South Korea)",
          // "la":    "Latin",
          // "lv":    "Latvian",
          // "lt":    "Lithuanian",
          // "lb":    "Luxembourgish",
          // "mk":    "FYRO Macedonian",
          // "ms":    "Malay",
          // "ml":    "Malayalam",
          // "mt":    "Maltese",
          // "mi":    "Maori",
          // "mr":    "Marathi",
          // "mo":    "Moldavian",
          // "nv":    "Navajo",
          // "ng":    "Ndonga",
          // "ne":    "Nepali",
          "no":    "Norwegian",
          // "nb":    "Norwegian (Bokmal)",
          // "nn":    "Norwegian (Nynorsk)",
          // "oc":    "Occitan",
          // "or":    "Oriya",
          // "om":    "Oromo",
          // "fa":    "Persian",
          // "fa-ir": "Persian/Iran",
          "pl":    "Polish",
          "pt":    "Portuguese",
          "pt-BR": "Portuguese (Brazil)",
          // "pa":    "Punjabi",
          // "pa-in": "Punjabi (India)",
          // "pa-pk": "Punjabi (Pakistan)",
          // "qu":    "Quechua",
          // "rm":    "Rhaeto-Romanic",
          "ro":    "Romanian",
          "ro-MO": "Romanian (Moldavia)",
          "ru":    "Russian",
          "ru-MO": "Russian (Moldavia)",
          // "sz":    "Sami (Lappish)",
          // "sg":    "Sango",
          // "sa":    "Sanskrit",
          // "sc":    "Sardinian",
          // "sd":    "Sindhi",
          // "si":    "Singhalese",
          // "sr":    "Serbian",
          // "sk":    "Slovak",
          // "sl":    "Slovenian",
          // "so":    "Somani",
          // "sb":    "Sorbian",
          "es":    "Spanish",
          "es-AR": "Spanish (Argentina)",
          "es-BO": "Spanish (Bolivia)",
          "es-CL": "Spanish (Chile)",
          "es-CO": "Spanish (Colombia)",
          "es-CR": "Spanish (Costa Rica)",
          "es-DO": "Spanish (Dominican Republic)",
          "es-EC": "Spanish (Ecuador)",
          "es-SV": "Spanish (El Salvador)",
          "es-GT": "Spanish (Guatemala)",
          "es-HN": "Spanish (Honduras)",
          "es-MX": "Spanish (Mexico)",
          "es-NI": "Spanish (Nicaragua)",
          "es-PA": "Spanish (Panama)",
          "es-PY": "Spanish (Paraguay)",
          "es-PE": "Spanish (Peru)",
          "es-PR": "Spanish (Puerto Rico)",
          "es-ES": "Spanish (Spain)",
          "es-UY": "Spanish (Uruguay)",
          "es-VE": "Spanish (Venezuela)",
          // "sx":    "Sutu",
          // "sw":    "Swahili",
          "sv":    "Swedish",
          "sv-FI": "Swedish (Finland)",
          "sv-SV": "Swedish (Sweden)",
          // "ta":    "Tamil",
          // "tt":    "Tatar",
          // "te":    "Teluga",
          // "th":    "Thai",
          // "tig":   "Tigre",
          // "ts":    "Tsonga",
          // "tn":    "Tswana",
          "tr":    "Turkish",
          // "tk":    "Turkmen",
          // "uk":    "Ukrainian",
          // "hsb":   "Upper Sorbian",
          // "ur":    "Urdu",
          // "ve":    "Venda",
          // "vi":    "Vietnamese",
          // "vo":    "Volapuk",
          // "wa":    "Walloon",
          // "cy":    "Welsh",
          // "xh":    "Xhosa",
          // "ji":    "Yiddish",
          // "zu":    "Zulu",
        }

      },
      formValidate: {
        collectOptions: {
          proofOfScan: true,
          pageTimeoutSeconds: 1,
          videosPlayDurationSeconds: 1,
          pageVideosAutoplay: false,
          pageScrollDurationSeconds: 3,
          useAuth: false,
          authStartUrl: null,
          authUsername: null,
          authPassword: null,
          maxUrls: 4,
          browser: 'chrome_v93_amd64',
          userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Agnostik/1.0',
          sitemapLimitedScope: false,
          timeSlots: [],
          countryCode: 'FRA',
          browserLanguages: ['fr-FR'],
        },
        displayName: 'exemple',
        authUsername: null,
        authPassword: null,
        websites: '',
        description: '',
        collectFrequency: 'daily',
        _sri_seller_category: '',
        isCollectEnabled: false,
        isEnabled: true,
        isAutoDetectConsentEnabled: true,
        origin: 'Agnostik'
      },
      formRules: {
        authUsername: [
          {
            type: 'string',
            validator: (rule, value) => { return this.formValidate.collectOptions.authUsername.length >= 3},
            required: true,
            trigger: 'blur',
            message: this.$t('global.validation.char_min')

          }
        ],
        authPassword: [
          {
            type: 'string',
            validator: (rule, value) => { return this.formValidate.collectOptions.authPassword.length >= 3},
            min: 3,
            required: true,
            trigger: 'blur',
            message: this.$t('global.validation.char_min')

          }
        ],
        displayName: [
          {
            type: 'string',
            required: true,
            min: 3,
            whitespace: true,
            message: this.$t('global.validation.char_min'),
            trigger: 'blur'
          }
        ],
        websites: [
          {
            type: 'string',
            // validator: (rule, value) => !!value.match(this.urlRegex),
            validator: (rule, value) => {
              if (this.file != false){
                return true
              }
              try {
                let url = new URL(value)
                return url.protocol === "http:" || url.protocol === "https:"
              } catch (_) {
                return false
              }
            },

            message: this.$t('global.validation.invalid_url'),
            required: true,
            min: 6,
            whitespace: true,
            trigger: 'blur'
          }
        ],
        collectFrequency: [
          {
            type: 'string',
            required: true,
            trigger: 'blur',
            message: this.$t('global.validation.collect_frequency')

          }
        ]
      },
      modalOpen: false
    }
  },
  watch: {
    formValidate: {
      deep: true,
      handler: function (val, old) {
        if (val.collectOptions.authUsername !== old.collectOptions.authUsername) this.formValidate.authUsername = val.collectOptions.authUsername
      }
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole'
    }),
    estimateCost () {
      const nbCollect = this.formValidate.collectOptions.timeSlots.length || 1
      // content: (row.isEnabled * row.isCollectEnabled) ? (Math.round(row.isEnabled * row.isCollectEnabled * (row.collectFrequency == "daily" ? 47 : 4) * ( row.collectOptions ? row.collectOptions.maxUrls : '15') * (this.userHasRole('super-admin')?0.015:0.99)) +" €") : ''
      return (Math.round((this.formValidate.collectFrequency == "daily" ? 47 : 4) * (this.formValidate.collectOptions.maxUrls)) * ( (this.formValidate.collectOptions.browser == 'chrome_v93_amd64' ||this.formValidate.collectOptions.browser == '' ) ? 0.02 : 0.0165)) * nbCollect + '€'
    },
    // estimatePrice () {

    //   // content: (row.isEnabled * row.isCollectEnabled) ? (Math.round(row.isEnabled * row.isCollectEnabled * (row.collectFrequency == "daily" ? 47 : 4) * ( row.collectOptions ? row.collectOptions.maxUrls : '15') * (this.userHasRole('super-admin')?0.015:0.99)) +" €") : ''
    //   return (Math.round((this.formValidate.collectFrequency == "daily" ? 47 : 4) * (this.formValidate.maxUrls)) * (0.99)) + '€'
    // },
    canEdit () {
      return this.checkPermissionByRole('admin')
    },
    validationStatus () {
      const { formValidate, isFetching } = this
      if (!formValidate.validation_status) return ''
      if (formValidate.validation_status == 'SUCCEEDED') return 'Succeeded'
      else if (formValidate.validation_status == 'FAILED') return 'Failed'
      else {
        return 'In progress'
      }

    }
  },
  methods: {
    ...mapActions({
      propertiesGetProperties: 'property/getProperties',
      propertiesGetPropertyById: 'property/getPropertyById',
      propertiesCreateProperty: 'property/createProperty',
      propertiesEditPropertyById: 'property/editPropertyById',
      bulkCreateProperties: 'property/bulkCreateProperties'
    }),
    handleCustomUserAgentChange(){
      let matchingOption = this.options.userAgent.find(obj => obj.name === 'Custom')
      console.log(matchingOption)
      matchingOption.value = this.formValidate.collectOptions.userAgent
    },
    checkUrl(){
      const trimurl = this.formValidate.websites
      const a = document.createElement('a')
      a.href = (trimurl.toLowerCase().startsWith('http://')||trimurl.toLowerCase().startsWith('https://')) ? trimurl : 'https://'+ trimurl
      const targetUrl = a.host + ((a.pathname != "/")?a.pathname:'') + a.search + a.hash
      if(targetUrl.length){
        this.similarUrlProperties = []
        this.propertiesGetProperties({'url':targetUrl})
          .then(response => {
            this.similarUrlProperties = response.data.filter(prop => prop.id != this.routeQuery.propertyId)
          })

      }
    },
    checkDisplayName(){
      this.checkUrl()
      const trimurl = this.formValidate.websites
      const a = document.createElement('a')
      a.href = (trimurl.toLowerCase().startsWith('http://')||trimurl.toLowerCase().startsWith('https://')) ? trimurl : 'https://'+ trimurl
      const targeturl = a.host + ((a.pathname != "/")?a.pathname:'') + a.search + a.hash
      this.formValidate.displayName = "["+ this.formValidate.collectOptions.countryCode +"] " + targeturl
      if(targeturl.length){
        this.similarProperties = []
        this.propertiesGetProperties({'q':this.formValidate.displayName})
          .then(response => {
            this.similarProperties = response.data.filter(prop => prop.id != this.routeQuery.propertyId)
          })

      }
    },
    handleUpload (file) {
      this.file = file
      this.parseCsv()
      return false
    },
    async parseCsv () {
      const t = this
      await Papa.parse(this.file, {
        complete: function (results) {
          if (results.errors.length || (results?.data[0][0] !== 'website' || results?.data[0][1] !== 'name' || results?.data[0][2] !== 'countryCode' || results?.data[0]?.length !== 3)) {
            t.file = null
            return t.instance('fail')
          }
          var data = results.data.reverse()

          data.length = data.length - 1
          t.parsedFile = data
          t.instance('success')
        }
      })

    },
    checkAntiBot () {
      const resp = this.dataResp
      let isHttpResp = null
      try {
        isHttpResp = resp.data.validation_last_metadata.httpResponse
      } catch (er) {
        return 'N/A'
      }
      if (isHttpResp) {
        return isHttpResp.botProtection ? isHttpResp.botProtection : 'Undefined'
      }
      return 'N/A'
    },
    resetAuthOptions (val) {
      if (!val) {
        this.formValidate.collectOptions.authUsername = null
        this.formValidate.collectOptions.authPassword = null
        this.formValidate.collectOptions.authStartUrl = null
      }
    },
    redirectTo(url) {
      const link = document.createElement('a')
      document.getElementsByTagName('body')[0].appendChild(link)
      link.setAttribute('href', url)
      link.setAttribute('target', '_blank')
      link.click()
    },
    handleCollectChange (state) {
      if (state === false) {
        const baseConfig = {
          pageTimeoutSeconds: 5,
          pageVideosAutoplay: true,
          pageScrollDurationSeconds: 5,
          useAuth: false,
          authStartUrl: null,
          authUsername: null,
          authPassword: null,
          maxUrls: 15,
          browser: 'chrome_v93_amd64',
          userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36 Agnostik/1.0',
          browserLanguages: ['fr-fr'],
          sitemapLimitedScope: false,
          timeSlots: [],
          countryCode: 'FRA'
        }
        this.formValidate = Object.assign(this.formValidate, baseConfig)
      }
    },
    baseUserAgent () {
      // return 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.94 Safari/537.36 Agnostik/1.0'
      return(this.options.userAgent[0].value)
    },
    // change boolean to 0 , 1
    // to be accepted by the API
    removeBool () {
      const { formValidate } = this
      const copy = JSON.parse(JSON.stringify(formValidate))
      for (const [key, value] of Object.entries(copy)) {
        if (typeof value === 'boolean') {
          copy[key] = +value
        }
      }
      if (copy._sri_seller_category === '') copy._sri_seller_category = null
      return copy
    },
    prepareCollectPayload () {
      const data = this.removeBool()
      const {collectOptions} = this.formValidate
      for (const [key, val] of Object.entries(collectOptions)) {
        data[key] = val
      }
      if (!data.browser) data.browser = ''
      if (!data.userAgent === '') data.userAgent = null
      delete data.collectOptions
      return data
    },
    checkPermissionByRole (role) {
      return this.hasRole(role)
    },
    handleReset (name) {
      this.$refs[name].resetFields()
    },
    handleSubmit (name) {
      this.isFetching = true
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          return this.propertiesEditPropertyById({data: this.prepareCollectPayload(), id: this.routeQuery.propertyId})
            .then(resp => {
              this.isFetching = false
              this.$Notice.success({
                title: 'Success',
                desc: this.$t('global.admin.collect_in_progress')
              })
              return this.fetch()
            })
            .catch(er => {
              this.isFetching = false
              const errorsMsg = []
              for (let v of Object.values(er.data.data.errors)) {
                for (let er of Object.values(v)) {
                  errorsMsg.push(er)
                }
              }
              this.$Message.warning({
                content: `${errorsMsg.toString().replace(',', '<br>')}`,
                duration: 5
              })
            })
        }
        this.$Notice.warning({
          title: 'Error',
          desc: this.$t('Some field are invalid')
        })
        this.isFetching = false

      })
    },
    fetch () {
      this.isFetching = true
      return this.propertiesGetPropertyById(this.routeQuery.propertyId)
        .then(resp => {
          this.dataResp = resp
          console.log({resp})
          if (!resp.data.collectOptions) {
            resp.data.collectOptions = Object.assign({}, {maxUrls: 50, userAgent: null, isAutoDetectConsentEnabled: false})
          }
          for (const [key, val] of Object.entries(resp.data.collectOptions)) {
            resp.data[key] = val
          }
          resp.data.isAutoDetectConsentEnabled = !!resp.data.collectOptions.isAutoDetectConsentEnabled
          resp.data.isCollectEnabled = !!resp.data.isCollectEnabled
          resp.data.isEnabled = !!resp.data.isEnabled
          if (!resp.data.origin) resp.data.origin = 'Agnostik'
          delete this.formValidate.validation_status
          this.formValidate.collectOptions = Object.assign(this.formValidate.collectOptions, resp.data.collectOptions)
          if (this.formValidate.collectOptions.countryCode && !this.options.collectCountry.find(elm=> elm.countryCode === this.formValidate.collectOptions.countryCode)) {
            this.formValidate.collectOptions.countryCode = 'FRA'
          }
          delete resp.data.collectOptions
          this.formValidate = Object.assign(this.formValidate, resp.data)
          delete this.formValidate.collects
          this.isFetching = false
        })
        .catch(er => {
          this.isFetching = false
        })
    },
    instance (type, errors) {
      if (type === 'fail') {
        return this.$Message.warning('Your CSV file is not valid. Please user the example file')
      }
      const props = JSON.parse(JSON.stringify(this.parsedFile))
      if (type === 'error') {
        this.downloadNotMappedList(errors)
        const errorsAmout = errors.length
        const createdAmount = props.length - errorsAmout
        this.$Modal.warning({
          title: 'Property import status',
          width: '800px',
          onOk: () => {
            errorsAmout === 0 && this.$router.push('/admin/properties')
          },
          onCancel: () => {
            errorsAmout === 0 && this.$router.push('/admin/properties')
          },
          onVisibleChange: () => {
            errorsAmout === 0 && this.$router.push('/admin/properties')
          },
          content: `
            <span>Imported properties : ${createdAmount} </span>
            <br>
            <span>Failed imported properties: ${errorsAmout}</span>
            <br><br>
            <span style="font-size:12px">Detail below</span>
            <br><br>
            <ul>
            ${errors.map(elm => '<li> property <strong>' + elm.entity.urls[0] + '</strong> failed because <strong>name or website already exists</strong>' + '</li><br>')}
            </ul>
          `.replaceAll(',', '')
        })
      } else {
        this.$Modal.success({
          title: `Property list to import (${props.length})`,
          content: `
            <br>
            <ul>
            ${props.map(elm => '<li> property : <strong>' + elm[0] + '</strong></li><br>')}
            </ul>
          `.replaceAll(',', '')
        })
      }


    },
    bulkCreate (name) {
      // debugger
      if (this.importList.length < 1) return 0
      // this.isLoading = true
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          const data = this.parsedFile.map(elm => {

            if (!elm[1].length) {
              const trimurl = elm[0].trim()
              const a = document.createElement('a')
              // a.href = trimurl.toLowerCase().startsWith('http') ? trimurl : 'https://'+ trimurl
              a.href = (trimurl.toLowerCase().startsWith('http://')||trimurl.toLowerCase().startsWith('https://')) ? trimurl : 'https://'+ trimurl
              // const parsed = trimurl.includes('http') ? psl.parse(a.hostname) : psl.parse(trimurl)
              // if (!parsed || !parsed.domain) {
              //   parsed.domain = trimurl
              // }
              // elm[1] = parsed.domain

              elm[1] = a.host + ((a.pathname != "/")?a.pathname:'') + a.search + a.hash
            }
            if (!elm[2].length) {
              elm[2] = 'BEL'
            }
            this.formValidate.displayName = '['+ elm[2] +'] '+ elm[1]
            this.formValidate.websites = elm[0]
            return {
              displayName: this.prepareCollectPayload().displayName,
              ...this.prepareCollectPayload(),
              countryCode: elm[2],
              urls: [elm[0]]
            }
          })
          console.log(data)
          return this.bulkCreateProperties(data)
            .then(r => {
              if (r.success) {
                const { results } = r
                const failAmount = results.failed
                this.instance('error', failAmount)
              }
            })
            .finally (() => {
              this.isLoading = false
            })
        }
        else this.$Message.error('The form is invalid, please verify it')
      })
    },
    downloadNotMappedList (errors) {
      const array = []
      const columns = ['website', 'name', 'error']
      array.push(columns)
      errors.forEach(error => {
        // const errorNames = Object.values(error.errors).toString()
        array.push([error.entity.urls[0], error.entity.displayName, JSON.stringify(error.errors)])
      })
      const jsonCsv = JSON.parse(JSON.stringify(array))
      const csvContent = 'data:text/csv;charset=utf-8,' +
      jsonCsv.map(e => e.join(',')).join('\n')
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'non_imported_properties.csv')
      document.body.appendChild(link)

      link.click()
    },
    create (name) {
      if (this.file) return this.bulkCreate(name)
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          return this.propertiesCreateProperty(this.prepareCollectPayload())
            .then(resp => {
              this.$Message.success('success')
              this.isLoading = true
              this.$router.push({path: '/admin/properties/create', query: {propertyId: resp.data.id}})
              this.isLoading = false
            })
            .catch(er => {
              const errorsMsg = []
              for (let v of Object.values(er.data.data.errors)) {
                for (let er of Object.values(v)) {
                  errorsMsg.push(er)
                }
              }
              this.$Message.warning({
                content: `${errorsMsg.toString().replace(',', '<br>')}`,
                duration: 5
              })
            })
        } else {
          this.$Message.error('The form is invalid, please verify it')
        }
      })
    }
  },
  mounted () {
    const query = this.$route.query
    this.routeQuery = query
    if (query && query.propertyId) this.fetch()
    this.checkDisplayName()
    this.checkUrl()
  }
}
</script>

<style lang="scss">

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
    {
        transform: translateX(-10px);
        opacity: 0;
    }

    #property-extra-waiting-validation {
      color: #ff9373;
    }

    .FAILED {
      color: #d55d78;
    }
    .PENDING {
      color: #ff9373;
    }
    .QUEUED {
      color: #ff9373;
    }
    .SUCCEEDED {
      color: #81C327;
    }
    .zoom-hover:hover {
      cursor: zoom-in;
    }
    .ivu-card-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
</style>
